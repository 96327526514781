import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import moment from 'moment'
import ArchivesData from '../staticdata/ArchivesData'
import TagsData from '../staticdata/TagsData'
import LayoutListPage from '../components/layoutListPage'
import { useFirebase } from '../components/firebase/FirebaseContext'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'

export const query = graphql`
  query PostLatest($skip: Int, $limit: Int) {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
      skip: $skip
      limit: $limit
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            description
            tags
            date
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
      totalCount
    }
  }
`
const Latest = ({
  data,
  pageContext: { page, totalCount, totalPage, date },
}) => {
  useFirebase(firebase => {
    firebase.analytics().setCurrentScreen(window.location.pathname)
  }, [])

  const postItems = data.allMarkdownRemark.edges.map(item => {
    return {
      slug: item.node.fields.slug,
      imgObj: item.node.frontmatter.image.childImageSharp.fluid,
      title: item.node.frontmatter.title,
      date: moment(item.node.frontmatter.date).format('YYYY/MM/DD'),
      description: item.node.frontmatter.description,
      tags: item.node.frontmatter.tags.map(tag => {
        return { name: tag }
      }),
    }
  })
  const basePath = `/blog/latest`
  let nextPath = null
  if (page === 2) {
    nextPath = '/'
  } else if (2 < page) {
    nextPath = `${basePath}/${page - 1}`
  }
  const oldPath = page !== totalPage ? `${basePath}/${page + 1}` : null
  const title = 'ブログ'
  const description = 'ブログの最新一覧ページです。'

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta
          property="og:image"
          content={`${process.env.WEB_URL}img/apple-touch-icon.png`}
        />
      </Helmet>
      <Header activeType={0} />
      <LayoutListPage
        title={`ブログ`}
        subTitle={`${totalCount}件 (${page}/${totalPage}ページ)`}
        page={page}
        totalPage={totalPage}
        nextPath={nextPath}
        oldPath={oldPath}
        tagItems={TagsData()}
        archiveList={ArchivesData()}
        postItems={postItems}
      />
      <Footer />
    </>
  )
}

export default Latest
